import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { baseUrl } from '../BaseUrl';
import '../screen/Component/categores.css'; // Import the CSS file for styling

function Categories() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true); // Add loading state

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/GetCategories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoadingCategories(false); // Set loading to false after fetching
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => { 
    const { id, categoryName } = category;
    navigate(`/DisplayCategories/${id}/${categoryName}`);
  };
  console.log("this is categores",categories)
  return (
    <div style={{backgroundColor:"white"}}>
      <Helmet>
        <title>Beste Gutscheine und Rabattcodes - Getgutschein</title>
        <meta 
          name="description" 
          content="Entdecken Sie Top-Rabattgutscheine, Aktionscodes und saisonale Angebote, um bei Ihren Lieblingsprodukten zu sparen. Täglich aktualisiert mit exklusiven Angeboten." 
        />
        <meta 
          name="keywords" 
          content="Rabattgutscheine, Gutscheincodes, Aktionscodes, exklusive Angebote, saisonale Rabatte, Geld sparen, beste Angebote, Online-Shopping-Angebote" 
        />
      </Helmet>

      <Navbar />
      
      <div className="categories-section">
        <div style={{
          fontFamily: "Poppins",
          fontSize: 20,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          paddingTop: "2%",
          paddingBottom: "2%"
        }}>
          Top Kategorien
        </div>

        <div className="d-flex flex-wrap justify-content-center" style={{marginBottom:10}}>
          {loadingCategories ? (
            <p>Loading...</p>
          ) : (
            <div className="categories-container">
              <div className="overlay">
                <div className="categories-list">
                  {/* Display categories */}
                  {categories.map((category) => (
                    <div 
                      key={category.id} 
                      className="category-item" 
                      onClick={() => handleCategoryClick(category)}
                    >
                      {/* Category info and icons */}
                      <div className="category-info1">
                        <p className="category-name1">
                          {category.categoryName}
                        </p>
                        <div className="arrow-container">
                          <span className="arrow-icon">
                            <FaLongArrowAltRight size={30} />
                          </span>
                        </div>
                      </div>
                      
                      {/* Category icon */}
                      <div className="cart-container">
                        <img 
                          src={category.categoriesicon} 
                          alt={category.categoryName} 
                          loading="lazy" 
                          style={{ width: 110, height: 110 }} 
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Categories;
