import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from './Component/Navbar'
import Footer from './Component/Footer'
import TopCoupon from './Component/TopCoupon'


function Blog() {
  return (
    <>
    <Helmet>
  <title>Gutschein-Blog und Tipps - Getgutschein</title>
  <meta name="description" content="Lesen Sie die neuesten Tipps, Trends und Artikel über Rabattgutscheine, Aktionscodes und Sparmöglichkeiten. Erfahren Sie, wie Sie das Beste aus Ihren Online-Einkäufen machen können." />
  <meta name="keywords" content="Gutschein-Blog, Spar-Tipps, Rabattgutscheine, Gutscheincodes, Aktionscodes, Sparmöglichkeiten, Online-Shopping-Rabatte, Angebote und Rabatte, Einkaufs-Ratgeber" />
</Helmet>

      <Navbar />
   
    <Footer/>
    </>
  )
}

export default Blog