import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import react-slick
import "slick-carousel/slick/slick.css"; // Import slick-carousel styles
import "slick-carousel/slick/slick-theme.css";
import './style1.css'
import Aos from "aos";
function Carousel() {
  const [lostores, setLoStores] = useState([
    {
      _id: '1',
      storeName: 'Anker',
      StoreImage: '/Anker.jpg',
      storeUrl: 'https://getgutschein.de/OpenStore/40/Anker' // Add URLs
    },
    {
      _id: '2',
      storeName: 'Desigual',
      StoreImage: '/Desigual.png',
      storeUrl:"https://getgutschein.de/OpenStore/43/Desigual",
    },
    {
      _id: '3',
      storeName: 'Christ',
      StoreImage: '/Christ.png',
      storeUrl:"https://getgutschein.de/OpenStore/42/CHRIST",

    },
    {
      _id: '4',
      storeName: 'Acer',
      StoreImage: '/Acer.png',
      storeUrl:"https://getgutschein.de/OpenStore/45/Acer",

},
    {
      _id: '5',
      storeName: 'Reebok',
      StoreImage: '/Reebok.jpg',
      storeUrl:"https://getgutschein.de/OpenStore/49/Reebok",

    },
    {
      _id: '6',
      storeName: 'NV GALLERY',
      StoreImage: '/NVGALLERY.jpg',
      storeUrl:"https://getgutschein.de/OpenStore/48/NV%20GALLERY",

    },
    {
      _id: '7',
      storeName: 'Skylum ',
      StoreImage: '/Skylum.png',
      storeUrl:"https://getgutschein.de/OpenStore/52/Skylum",

},
    {
      _id: '8',
      storeName: 'philips',
      StoreImage: '/philips.jpg',
      storeUrl:"https://getgutschein.de/OpenStore/56/Philips",

},
    {
      _id: '9',
      storeName: 'Eufy',
      StoreImage: '/Eufy.png',
      storeUrl:"https://getgutschein.de/OpenStore/70/Eufy",

},
    {
      _id: '10',
      storeName: 'Crocs',
      StoreImage: '/Crocs.jpg',
      storeUrl:"https://getgutschein.de/OpenStore/71/Crocs",

},
    {
      _id: '11',
      storeName: 'Joom',
      StoreImage: '/Joom.jpg',
      storeUrl:"https://getgutschein.de/OpenStore/60/Joom",

},
    {
      _id: '12',
      storeName: 'AUTODOC',
      StoreImage: '/AUTODOC.jpeg',
      storeUrl:"https://getgutschein.de/OpenStore/53/AUTODOC",

},
  ]);

  // Slick settings for the carousel
  const settings = {
    dots: false, // Hide navigation dots
    infinite: true, // Infinite scrolling
    speed: 1000, // Animation speed (1 second)
    slidesToShow: 6, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Scroll every 5 seconds
    pauseOnHover: true, // Pause on hover
    arrows: true, // Hide navigation arrows
    responsive: [
      {
        breakpoint: 1200, // For screens 1200px and below
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992, // For screens 992px and below
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768, // For screens 768px and below
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576, // For screens 576px and below
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400, // For screens 400px and below
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleStoreClick = (storeUrl) => {
      window.open(storeUrl, '_blank'); // Open the URL in a new tab or window
    };
    useEffect(() => {
      Aos.init();
    }, []);
  return (
      <div 
      className="carousel-container" 
      style={{
        marginTop: '8%',
        backgroundColor: 'white',
        borderRadius: 0,
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data-aos="fade-up"
     data-aos-duration="6000"
    >
      <div style={{ width: '90%' }}>
        <Slider {...settings}>
          {lostores.map((store) => (
            <div
              key={store._id}
              onClick={() => handleStoreClick(store.storeUrl)} // Pass the URL to the click handler
              style={{
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: '0px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
                  textAlign: 'center',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '90%',
                  margin: '0 10px',
                  height: '120px',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={store.StoreImage}
                  alt={store.storeName}
                  style={{
                    width: '80%',
                    height: '50px',
                    objectFit: 'contain',
                    marginBottom: '10px',
                  }}
                />
                <div style={{ fontWeight: 'bold' }}>{store.storeName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;
