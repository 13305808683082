import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

function TextPage() {
      const editorRef = useRef(null);

      useEffect(() => {
        const editor = grapesjs.init({
          container: editorRef.current,
          fromElement: true,
          height: '100vh',
          width: 'auto',
          storageManager: false,
          panels: { defaults: [] },
          blockManager: {
            appendTo: '#blocks',
            blocks: [
              {
                id: 'section', // id is mandatory
                label: '<b>Section</b>', // You can use HTML/SVG inside labels
                attributes: { class:'gjs-block-section' },
                content: `<section>
                            <h1>This is a simple title</h1>
                            <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
                          </section>`,
              },
              {
                id: 'text',
                label: 'Text',
                content: '<div data-gjs-type="text">Insert your text here</div>',
              },
              {
                id: 'image',
                label: 'Image',
                select: true,
                content: { type: 'image' },
                activate: true,
              },
              {
                id: 'column',
                label: 'Column',
                content: '<div class="column"></div>',
              },
              {
                id: 'button',
                label: 'Button',
                content: '<button class="btn">Click Me</button>',
              },
            ],
          },
        });
    
        // Store the editor instance to use it later
        return () => editor.destroy();
      }, []);
  return (
      <div>
      <div id="blocks"></div>
      <div ref={editorRef}></div>
    </div>
  );
}

export default TextPage;
