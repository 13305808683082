// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
      apiKey: "AIzaSyAxxVdbCw6jXBPhp3vwrKkx2s4qb5cEoW4",
      authDomain: "getgutschein-aafd5.firebaseapp.com",
      databaseURL: "https://getgutschein-aafd5-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "getgutschein-aafd5",
      storageBucket: "getgutschein-aafd5.appspot.com",
      messagingSenderId: "592505997856",
      appId: "1:592505997856:web:8efa6dfe5b002524b34a45",
      measurementId: "G-CPT26NDJNR"
};

if (!firebase.apps.length){
    firebase.initializeApp(firebaseConfig);
}


export const imageDb=getStorage();
export { firebase };

