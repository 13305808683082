import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
import './categores.css'; // Import the CSS file for styling
import { TiShoppingCart } from "react-icons/ti";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function Categores() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/GetCategories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  const handleCategoryClick = (category) => { 
    const { id, categoryName } = category;
    navigate(`/DisplayCategories/${id}/${categoryName}`);
     };

  return (
    <div className="categories-container">
      <div className="overlay">
        <h1 className="title"> </h1>
        <div className="categories-list">
          {/* Display the first four categories */}
          {categories.map((category, index) => (
            <div key={category.id} className="category-item" onClick={() => handleCategoryClick(category)}>
          
              <div className="category-info1">
                <p className="category-name1">
                  {category.categoryName}
                </p>
                <div className="arrow-container">
                  <span className="arrow-icon"><FaLongArrowAltRight size={30} /></span>
                </div>
              </div>
              
          
              <div className="cart-container">
                <img src={category.categoriesicon} alt={category.categoryName} loading="lazy"  style={{ width: 110, height: 110 }} />
              </div>
            </div>
          ))}
 {/* <img src="/error.jpg" alt="under development" style={{width:"100%",height:"50%"}}/> */}
    
        </div>
      </div>
    </div>
  );
}

export default Categores;
