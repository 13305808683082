import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub } from 'react-icons/fa'; // Import social media icons from react-icons
import { FaRegCopyright } from "react-icons/fa";
import { Link } from 'react-router-dom';
function Footer() {
  return (<>
    <div className="footer">
      <hr /> {/* Top border line */}
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-2">
            <a href="#about">About Getgutschein</a>
            <p>About Getgutschein</p>
            <p>Contact us</p>
            <Link to={"/Dataprotection"}>Datenschutz</Link>
            <p>Blog</p>
            <Link to={'/Impressum'}>Impressum</Link>
          </div>
          <div className="col-md-2">
            <a href="#services">COUPONS:</a>
            <p>Featured Coupons</p>
            <p>Printable Coupons</p>
            <p>Online Coupons</p>
            <p>Grocery Coupons</p>
            <p>Free Shipping Coupons</p>
            <p>All Coupons</p>
          </div>
          <div className="col-md-2">
            <a href="#contact">DEALS:</a>
            <p>Today’s Deals</p>
            <p>Top Deals</p>
            <p>Daily Deals</p>
            <p>Clearance Deals</p>
            <p>Discounted Deals</p>
            <p>All Deals</p>
          </div>
          <div className="col-md-2">
            <a href="#privacy">STORES:</a>
            <p>Popular Stores</p>
            <p>Online Stores</p>
            <p>Local Stores</p>
            <p>Department Stores</p>
            <p>All Stores</p>
          </div>
          <div className="col-md-2">
            <a href="#terms">CATEGORIES:</a>
            <p>Top Categories</p>
            <p>Fashion</p>
            <p>Electronics</p>
            <p>Travel</p>
            <p>Home & Garden</p>
            <p>All Categories</p>
          </div>
        </div>
        <hr style={{ marginTop: "5%" ,width:"98%"}} />
        <div className="row mb-4">
          <div className="col-md-8 offset-md-4">
            <b  style={{fontFamily:"poppine",fontSize:'1.5rem',paddingLeft:"9%",}}>Verpassen Sie keine weiteren tollen Angebote!</b>
            <form className="email-form">
              <input type="email" placeholder="Enter your email" />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="row justify-content-right mb-4">
          <div className="col-auto">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaLinkedinIn />
            </a>
            <a href="https://www.github.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaGithub />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div style={{
  backgroundColor: "#2b2b2b",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: 50
}}>
  <FaRegCopyright  style={{
    color: "white",
    marginRight: 8 // Spacing between icon and text
  }}/>
  <h6 style={{
    fontFamily: "Poppins",
    color: "white",
    fontWeight: "500",
    fontSize: 18,
    textAlign: "center",
    margin: 0
  }}>
    CopyRight 2024 Getgutschein.de All Rights Reserved
  </h6>
</div>

  </>

  );
}

export default Footer;
