import React, { useEffect, useState } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import axios from 'axios';
import { FaPercent, FaEuroSign } from "react-icons/fa";
import { baseUrl } from '../BaseUrl';
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { TiTick } from "react-icons/ti";
import { RiCoupon2Fill } from "react-icons/ri";
import { Helmet } from 'react-helmet';

function TopCoupon() {
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getTopcoupon = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectTopCoupon`);
      // console.log(response.data)
      setCoupon(response.data)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false when the data is fetched
    }
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS
    getTopcoupon();
  }, []);

  const handlecouponclick = (coupon) => {
    navigate(`/OpenStore/${coupon.Store_ID}/${coupon.storeName}`, { state: coupon });
  };

  return (
    <div>
      <Helmet>
  <title>Gutscheine und Rabattcodes - Getgutschein</title>
  <meta name="description" content="Finden Sie die neuesten Gutscheine und Rabattcodes, um bei Ihren Einkäufen zu sparen. Täglich aktualisierte Angebote für eine Vielzahl von Produkten und Geschäften." />
  <meta name="keywords" content="Gutscheine, Rabattcodes, Angebote, Sparen, Online-Shopping, Gutscheincodes, exklusive Gutscheine, Einkaufsgutscheine, Sonderangebote" />
</Helmet>
      <Navbar />
      <div style={{ backgroundColor: "white" }}>
        <div className="container">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            Top Gutschein
          </div>
          
          {loading ? (
            <div style={{ textAlign: "center", padding: "50px 0" }}>
              <img src='/voucher.gif' style={{ width: 100, height: 100 }} alt="Loading" />
            </div>
          ) : (
            <div className="coupon-container">
              {coupon.map((coupon, index) => (
                <div
                  className="coupon-card"
                  onClick={() => handlecouponclick(coupon)}
                  style={{ cursor: "pointer", transition: "transform 0.3s ease-in-out" }}
                  key={index}
                  data-aos="zoom-in-down"
                >
                  <div className="image-wrapper">
                    {/* Lazy loading the coupon image */}
                    <img
                      src={coupon.CouponImage}
                      alt={coupon.StoreName}
                      className="coupon-image"
                      style={{objectFit:"inherit"}}
                      loading="lazy"
                    />
                    {/* Lazy loading the store image */}
                    <img
                      src={coupon.StoreImage}
                      alt={coupon.StoreName}
                      className="store-image"
                      loading="lazy"
                    />
                  </div>
                  
                  {/* Coupon Title */}
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 17,
                      fontWeight: "700",
                      paddingBottom: "10px",
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    {coupon.couponTittle}
                  </div>

                  {/* Coupon confirmation status */}
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", borderBottom: "1px solid #c7c9c9" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginLeft: 10 }}>
                      <input type="checkbox" id="confirmOffer" checked style={{ display: "none" }} />
                      <label
                        htmlFor="confirmOffer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          marginTop: 5,
                        }}
                      >
                        <span
                          style={{
                            width: "16px",
                            height: "16px",
                            border: "1px solid black",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "8px",
                            position: "relative",
                          }}
                        >
                          <TiTick size={25} color="red" />
                        </span>
                        <span style={{ fontFamily: "Poppins", color: "red" }}>Bestätigt</span>
                      </label>
                    </div>
                  </div>

                  {/* Campaign button */}
                  <div
                    style={{
                      width: "100%",
                      height: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Poppins",
                      fontSize: 18,
                      color: "blue",
                      fontWeight: "initial",
                    }}
                  >
                    <RiCoupon2Fill size={25} />
                    To the campaign
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TopCoupon;
