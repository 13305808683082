import React, { useEffect, useState } from "react";
import HeroSection from "./Component/HeroSection";
import { FaPercent, FaEuroSign, FaStar, FaLongArrowAltRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import { baseUrl } from "../BaseUrl";
import Footer from "./Component/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { TiTick } from "react-icons/ti";
import { RiCoupon2Fill } from "react-icons/ri";
import Categores from "./Component/Categores";
import SwipImage from "./Component/SwipImage";
import { Helmet } from 'react-helmet';



function Home() {
  const navigate = useNavigate();
  const [selectedStores, setSelectedStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [storesPerPage] = useState(15);
  const [coupons, setCoupons] = useState({});
  const [couponLoading,setCouponLoading]=useState(false)
  // Fetch stores data
  const getDataFromselectStore = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectStore`);
      // console.log("API Response", response.data);
      const result = response.data;
      const sortedData = result.sort(
        (a, b) => new Date(b.create_time) - new Date(a.create_time)
      );
      setSelectedStores(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch coupons for stores
  const getCoupons = async () => {
    try {
      const storeIds = selectedStores.map((store) => store.storeID);
      const promises = storeIds.map((id) =>
        axios.get(`${baseUrl}api/coupons/store/${id}`)
      );
      const responses = await Promise.all(promises);
      const couponsByStore = responses.reduce((acc, response, index) => {
        const storeId = storeIds[index];
        const allCoupons = response.data;
        const latestCoupon = allCoupons
          .sort((a, b) => new Date(b.createtime) - new Date(a.createtime))
          .find(coupon => new Date(coupon.createtime) <= new Date());

        if (latestCoupon) {
          acc[storeId] = latestCoupon;
        }
        return acc;
      }, {});
      setCoupons(couponsByStore);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }finally {
      setCouponLoading(false);
    }
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  useEffect(() => {
    getDataFromselectStore();
  }, []);

  useEffect(() => {
    if (selectedStores.length > 0) {
      getCoupons();
    }
  }, [selectedStores]);

  const handleGridClick = (store) => {
    navigate(`/OpenStore/${store.storeID}/${store.storeName}`, { state: store });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastStore = (currentPage + 1) * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = selectedStores.slice(
    indexOfFirstStore,
    indexOfLastStore
  );
// console.log("this is ",selectedStores)
const [coupon, setCoupon] = useState([]);
const getTopcoupon = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/SelectTopCoupon`);
    // console.log(response.data)
    setCoupon(response.data)
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false); // Set loading to false when the data is fetched
  }
};

useEffect(() => {
  getTopcoupon();
}, []);


// console.log(coupon)
const handlecouponclick = (coupon) => {
  // alert(JSON.stringify(coupon.Store_ID))
  navigate(`/OpenStore/${coupon.Store_ID}/${coupon.storeName}`, { state: coupon });
};
return (
    <>
    <Helmet>
  <title>Getgutschein.de | Top Gutscheine & Aktuelle Rabattcodes</title>
  <meta name="description" content="Finden Sie exklusive Gutscheine, Rabattcodes und Top-Angebote bei Getgutschein. Sparen Sie bei Ihren Einkäufen mit den besten aktuellen Gutscheinen und Angeboten für beliebte Marken." />
  <meta name="keywords" content="Gutscheine, Rabattcodes, exklusive Gutscheine, Sparen, Online-Shopping, Angebote, Sonderangebote, Gutscheincodes, Deals, Top-Marken, Rabattaktionen" />
</Helmet>
      <HeroSection />
      <div style={{ backgroundColor: "white" }}>
      <div className="container">
  <div
    style={{
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "2%",
      color:"#fb7b1c"
    }}
  >
    Top Gutscheine 
  </div>
  {couponLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              
  <div className="coupon-container">
  {coupon.map((coupon, index) => (
    <div
      className="coupon-card"
      onClick={() => handlecouponclick(coupon)}
      key={index}
      data-aos="zoom-in-down"
    >
      <div className="image-wrapper" >
        <img
          src={coupon.CouponImage}
          alt={coupon.StoreName}
          className="coupon-image"
          style={{objectFit:"inherit"}}
          loading="lazy" // Lazy load coupon image
        />
        <img
          src={coupon.StoreImage}
          alt={coupon.StoreImage}
          className="store-image"
          loading="lazy" // Lazy load coupon image
        />
      </div>
      <div style={{ textAlign: "center", fontFamily: "Poppins", marginTop: 10 }}>
        {coupon.couponTittle}
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #c7c9c9", marginTop: 10 }}>
        <label
          htmlFor="confirmOffer"
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: 5,
          }}
        >
          <span
            style={{
              width: "16px",
              height: "16px",
              border: "1px solid black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
              left:10
            }}
          >
            <TiTick size={25} color="red" />
          </span>
          <span style={{ fontFamily: "Poppins", color: "red" }}>Bestätigt</span>
        </label>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight:"bold",
          color:"#fb7b1c"
        }}
      >
        Angebot anzeigen
        <FaLongArrowAltRight size={40} color="#fb7b1c" style={{paddingLeft:"10px"}} />

        {/* <img src="/coupon.gif" style={{ width: 50, height: 50 }} /> */}
      </div>
    </div>
  ))}
</div>
  // <img src="/error.jpg" alt="under development" style={{width:"100%",height:"50%"}}/>

)}
<div
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
                 color:"#fb7b1c"
            }}
          >
           
          Zeit sparen
          </div>
            <SwipImage/>
<div
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
                 color:"#fb7b1c"
            }}
          >
            Beliebte Kategorien
          </div>
             <Categores/>
</div>

        <div className="container">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
                 color:"#fb7b1c"
            }}
          >
            Top-Angebote des Tages
          </div>
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="row">
                  {currentStores.map((store) => (
                    <div
                    key={store.id}
                    className="col-12 col-md-6 col-lg-3 mb-3"
                    data-aos="zoom-in-up"
                  >
                    <div
                      className="card"
                      style={{
                        width: "100%",
                        backgroundColor: "#eef1f1",
                        height: "100%",
                        borderRadius: 8,
                        border: "1px solid #c7c9c9",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
                        cursor: "pointer",
                        transition: "transform 0.3s ease-in-out", // Added transition for smooth zoom effect
                      }}
                      onClick={() => handleGridClick(store)}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)"; // Zoom in on hover
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)"; // Reset to original size on hover out
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "30%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottom: "1px solid #c7c9c9",
                        }}
                      >
                        <img
                          src={`${store.StoreImage}`}
                          alt={store.storeName}
                          loading="lazy"
                          style={{
                            width: "90%",
                            height: "80%",
                            maxHeight: "150px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "50%",
                          borderBottom: "1px solid #c7c9c9",
                          padding: "10px",
                        }}
                      >
                        {coupons[store.storeID] ? (
                          <>
                            <p
                              style={{
                                fontFamily: "cursive",
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              {coupons[store.storeID].Discounts}
                              {coupons[store.storeID].DiscountsIn === "Euro" ? (
                                <FaEuroSign />
                              ) : (
                                <FaPercent />
                              )}
                              <b
                                style={{
                                  fontSize: 25,
                                  fontFamily: "monospace",
                                }}
                              >
                                {" "}
                                Discount
                              </b>
                            </p>
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontSize: 17,
                                fontWeight: "700",
                                paddingBottom: "10px",
                              }}
                            >
                              {coupons[store.storeID].TitleCoupon}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            {/* Confirm Offer Checkbox */}
                            <div style={{ display: "flex", alignItems: "center",justifyContent:"flex-end" }}>
                          <input
                            type="checkbox"
                            id="confirmOffer"
                            checked
                            style={{ display: "none" }} // Hide the default checkbox
                          />
                          <label
                            htmlFor="confirmOffer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                          >
                            {/* Custom Checkbox */}
                            <span
                              style={{
                                width: "16px",
                                height: "16px",
                                border: "1px solid black",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center", // Center the tick icon
                                marginRight: "8px",
                                position: "relative",
                              }}
                            >
                              <TiTick size={25} color="red" /> {/* Adjusted the size and color */}
                            </span>
                            <span style={{ fontFamily: "Poppins", color: "red" }}>Bestätigt</span>
                          </label>
                        </div>
                            
                            {/* Rating Stars */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {[...Array(4)].map((star, index) => (
                                <FaStar key={index} color="#ffc107" style={{ marginLeft: "5px" }} />
                              ))}
                            </div>
                          </div>
                          </>
                        ) : (
                          <p>No latest coupon available</p>
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "20%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontSize: 18,
                          fontWeight: "initial",
                        }}
                      >
                        To the campaign
                      </div>
                    </div>
                  </div>
                  
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    paddingBottom: "20px",
                  }}
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(selectedStores.length / storesPerPage)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div>
       
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
