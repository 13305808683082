import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { baseUrl } from '../BaseUrl';

function Shop() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedLetter, setSelectedLetter] = useState('');
  const [visibleStores, setVisibleStores] = useState(20); // Initially show 20 stores
  const loaderRef = useRef(null); // Reference to the loader element

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const getDataFromDB = async () => {
    try {
      const response = await axios.get(baseUrl + `api/createStore/count`);
      const result = response.data;

      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setStores(sortedData);
      setFilteredStores(sortedData); // Initialize filteredStores with sortedData
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataFromDB();
  }, []);

  useEffect(() => {
    let results = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedLetter) {
      results = results.filter(store => store.storeName.toUpperCase().startsWith(selectedLetter));
    }

    setFilteredStores(results);
    setVisibleStores(20); // Reset visible stores when search term or letter changes
  }, [searchTerm, stores, selectedLetter]);

  // Function to handle store click
  const handleStoreClick = (store) => {
    navigate(`/OpenStore/${store.id}/${store.storeName}`, { state: store });
  };

  // Use IntersectionObserver to load more stores when scrolling
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && visibleStores < filteredStores.length) {
        setVisibleStores((prev) => prev + 20); // Load 20 more stores
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current); // Observe the loader
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // Clean up observer
      }
    };
  }, [filteredStores, visibleStores]);

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

  return (
    <div style={{ backgroundColor: "white", height: "auto" }}>
      <Helmet>
        <title>Shops und Gutscheine - Getgutschein.de</title>
        <meta name="description" content="Entdecken Sie eine Vielzahl von Geschäften mit den besten Rabattcodes und Gutscheinen. Finden Sie saisonale Angebote und exklusive Rabatte für Ihre Lieblingsshops." />
        <meta name="keywords" content="Shops, Geschäfte, Gutscheine, Rabattcodes, exklusive Rabatte, Online-Shopping, saisonale Angebote, Sparen" />
      </Helmet>
      <Navbar />
      <div className="container mt-4">
        <div className="row mb-4">
          <h3 style={{ fontFamily: "Poppins", fontWeight: "600" }}>Search by Store Name</h3>
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder="Search stores..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="row mb-4">
          <h4 style={{ fontFamily: "Poppins", fontWeight: "600", marginBottom: 10 }}>Find by Letter</h4>
          <div className="d-flex flex-wrap" style={{ gap: '5px' }}>
            {alphabet.map(letter => (
              <button
                key={letter}
                className={`btn btn-outline-primary me-2 mb-2 ${selectedLetter === letter ? 'active' : ''}`}
                onClick={() => setSelectedLetter(selectedLetter === letter ? '' : letter)}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src='/shopping-bag.gif' style={{ width: 100, height: 100 }} alt="Loading" />
              </div>
            ) : (
              <div className="row">
                {filteredStores.slice(0, visibleStores).map(store => (
                  <div
                    key={store.id}
                    className="col-md-3 mb-4"
                    data-aos="zoom-in-up"
                  >
                    <div
                      className="card"
                      style={{
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: "transparent",
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
                        transition: 'box-shadow 0.3s ease-in-out'
                      }}
                      onClick={() => handleStoreClick(store)}
                    >
                      {/* Lazy load the image */}
                      <div
                        className="card-img-container"
                        style={{
                          width: '100%',
                          height: '180px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                          border: '1px solid #9ea0a0',
                          marginBottom: '8px',
                          backgroundColor: 'White'
                        }}
                      >
                        <img
                          src={store.StoreImage}
                          alt={store.storeName}
                          className="card-img"
                          style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: "contain"
                          }}
                          loading="lazy" // Lazy loading applied here
                        />
                      </div>

                      {/* Render text immediately */}
                      <div
                        className="card-body text-center"
                        style={{
                          backgroundColor: 'White',
                          borderRadius: 5,
                          border: '1px solid #9ea0a0',
                          padding: '7px'
                        }}
                      >
                        <h5 className="card-title">{store.storeName}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Loader div for infinite scrolling */}
            <div ref={loaderRef} className="d-flex justify-content-center">
              {visibleStores < filteredStores.length && (
               <div className="d-flex justify-content-center align-items-center">
               <img src='/shopping-bag.gif' style={{ width: 100, height: 100 }} alt="Loading" />
             </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
