import React, { useEffect, useState } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FaSearch, FaTimes } from "react-icons/fa";
import "./style1.css";


import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/autoplay";
import "../../App.css";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import Aos from "aos";
import TypingAnimation from "./TypingAnimation";
import Carousel from "./Carousel";
import { IoArrowRedo } from "react-icons/io5";

function HeroSection() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);

  const toggleModal = () => setShowModal(!showModal);
  const closeModal = () => setShowModal(false);

  const getDataFromDB = async () => {
    try {
      const response = await axios.get(baseUrl+`api/createStore/count`);
      const result = response.data;
      const sortedData = result.sort(
        (a, b) => new Date(b.create_time) - new Date(a.create_time)
      );
      // console.log("this is search",sortedData)
      setStores(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataFromDB();
  }, []);
console.log(stores)
  useEffect(() => {
    if (searchTerm) {
      const results = stores.filter((store) =>
        store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStores(results);
    } else {
      setFilteredStores([]);
    }
  }, [searchTerm, stores]);

  const handleStoreClick = (store) => {
  //   alert(JSON.stringify(store))
    navigate(`/OpenStore/${store.id}/${store.storeName}`, { state: store })
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredStores([]);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="hero-section"
      style={{ position: "relative", overflow: "hidden", minHeight: "100vh" }}
    >
      {/* Background video */}
      <img
        src="/bg.jpg"
        alt="Background GIF"
        // className="blurred-video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />

      <div className="container" style={{ position: "relative", zIndex: 1 }}>
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{ backgroundColor: "transparent" }}
        >
          <Link
            to={"/"}
            className="navbar-brand"
            style={{
              fontFamily: "Poppins",
              fontSize: "2.3rem",
              textDecoration: "none",
              color: "black",
              fontWeight: "bolder",
            }}
          >
            <img
              src="/newlogo.png"
              alt="Getgutschein"
              style={{ maxWidth: "100%", height: "75px", borderRadius: 10 }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleModal}
          >
            <span className="navbar-toggler-icon" style={{backgroundColor:"#13d0fb"}}></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
            style={{ marginBottom: 25 }}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to={"/TopCoupon"}
                  className="nav-link"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Top Gutschein
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/shop"}
                  className="nav-link"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Alle shops
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/categories"}
                  className="nav-link"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Kategorien
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/Blog"}
                  className="nav-link"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* Centered Text and Search Bar */}
        <div className="d-flex flex-column align-items-center mt-5">
          {/* Centered Text */}
          <div className="text-center responsive-container">
            <div className="static-text-container">
              <span className="static-text" style={{ color: "#fb7b1c" }}>
              Sparen Sie mit dem besten Gutschein- und Rabattcode
              </span>
            </div>
            {/* <div className="static-text" style={{ color: "#fb7b1c" }}>
              <TypingAnimation
                texts={["Top Angebote", "bestes Angebot", "Schwarzer Freitag","Finden Sie den besten Rabatt",]}
                speed={140}
                eraseSpeed={100}
              />
            </div> */}
          </div>
          {/* Centered Search Bar */}
          <Form className="w-100 mt-3 d-flex justify-content-center">
            <InputGroup className="w-75 w-md-50">
              <InputGroup.Text
                style={{
                  backgroundColor: "white",
                  border: "0.7px solid green",
                  borderRight: "none",
                  height: "48px",
                }}
              >
                <FaSearch style={{ color: "#fb7b1c" }} />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Suche nach Shop und Kategorien"
                aria-label="Search"
                className="search-input"
                style={{
                  border: "0.7px solid green",
                  height: "48px",
                  borderLeft: "none",
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
                 <Button
                // variant="success"
                
                type="button"
                style={{ height: "48px",color:"white",backgroundColor:"#fb7b1c" }}
              >
                Search
              </Button>
            </InputGroup>
          </Form>
          <div className="text-container">
  <h3 className="text-heading">
    Finden Sie den besten Store für Gutscheine und die besten Angebote
  </h3>
</div>
          {/* Display Search Results */}
       
        </div>
        {searchTerm && (
            <div className="search-results" style={{ width: "40%", marginTop: "-6%",marginLeft:"15%" }}>
              {filteredStores.length > 0 ? (
                <ul className="list-unstyled">
                  {filteredStores.map((store) => (
                    <li key={store._id} className="store-item">
                      <div
                        // to={`/OpenStore/${store._id}/${store.storeName}`}
                        className="store-link"
                        style={{textDecoration:"none", color:"#fb7b1c",padding:10,height:"65px",cursor:"pointer" }}
                        onClick={() => handleStoreClick(store)}
                      >
                        <img
                          src={store.StoreImage}
                          alt={store.storeName}
                          style={{
                            width: "75px",
                            height: "60px",
                            objectFit: "contain",
                            borderRadius: "2%",
                          }}
                        />
                        <b style={{marginLeft:10 ,fontSize:17,textDecoration: "none"}}>{store.storeName}</b>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p style={{ color: "#fb7b1c", margin:10,fontWeight:"bolder"}}>Keine Geschäfte gefunden.</p>
              )}
            </div>
          )}
      </div>
  
      {/* Carousel Component */}
      <Carousel />




      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="navbar-nav">
          <li className="nav-item">
                <Link to={'/TopCoupon'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
                  
                <IoArrowRedo  size={25} color="black"/>
                  Top Gutschein
                </Link>
              </li>
            <li className="nav-item">
              <Link to={'/shop'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
              <IoArrowRedo  size={25} color="black"/>
                SHOP
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/categories'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
              <IoArrowRedo  size={25} color="black"/>
                CATEGORIES
              </Link>
            </li>
            <li className="nav-item">
                <Link to={'/Blog'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
                <IoArrowRedo  size={25} color="black"/>
                  Blogs
                </Link>
              </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HeroSection;
