import React, { useState, useEffect } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { baseUrl } from '../BaseUrl';

function DisplayCategories() {
  const navigate = useNavigate();
  const { categoryid } = useParams();
  const [selectedStores, setSelectedStores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch stores based on category ID from URL
  const openviewSelectedStore = async (categoryid) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}api/AddStore/Categories/${categoryid}`);
      const storeIds = response.data.storeIds;

      if (Array.isArray(storeIds)) {
        const uniqueStoreIds = [...new Set(storeIds.flat())];
        const storePromises = uniqueStoreIds.map(id => axios.get(`${baseUrl}api/createStore/${id}`));

        const storeResponses = await Promise.all(storePromises);
        setSelectedStores(storeResponses.map(response => response.data));
      } else {
        setSelectedStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categoryid) {
      openviewSelectedStore(categoryid);
    }
  }, [categoryid]);

  const handleStoreClick = (store) => {
    navigate(`/OpenStore/${store.id}/${store.storeName}`);
  };

  return (
    <div style={{backgroundColor:"white"}}>
      <Navbar />
      <Helmet>
        <title>Categories - Getgutschein.de</title>
        <meta name='description' content='Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote' />
      </Helmet>

      <div className='container' style={{ marginTop: '2rem' }}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <img src='/categories.gif' style={{ width: 100, height: 100, marginTop: 20 }} alt="Loading" />
          </div>
        ) : (
          <div className="row">
            {selectedStores.length > 0 ? (
              selectedStores.map(store => (
                <div key={store.id} className="col-md-3 mb-4">
                  <div
                    className="card"
                    style={{
                      cursor: 'pointer',
                      border: 'none',
                      backgroundColor: "transparent",
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
                      transition: 'box-shadow 0.3s ease-in-out'
                    }}
                    onClick={() => handleStoreClick(store)}
                  >
                    <div
                      className="card-img-container"
                      style={{
                        width: '100%',
                        height: '180px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5,
                        border: '1px solid #9ea0a0',
                        marginBottom: '8px',
                        backgroundColor: 'White'
                      }}
                    >
                      <img
                        src={store.StoreImage || '/path/to/fallback-image.jpg'} 
                        alt={store.storeName}
                        className="card-img"
                        loading="lazy"
                        style={{
                          maxWidth: '90%',
                          maxHeight: '90%',
                          objectFit: "contain"
                        }}
                      />
                    </div>
                    <div
                      className="card-body text-center"
                      style={{
                        backgroundColor: 'White',
                        borderRadius: 5,
                        border: '1px solid #9ea0a0',
                        padding: '7px'
                      }}
                    >
                      <h5 className="card-title">{store.storeName}</h5>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No stores available for this category.</div>
            )}
          </div>
        )}
      </div>
      
      <Footer />
    </div>
  );
}

export default DisplayCategories;
