import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screen/Home';
import Shop from './screen/Shop';
import Categories from './screen/Categories';
import OpenStore from './screen/OpenStore';

import ViewCategories from './screen/ViewCategories';

import { AuthProvider } from './screen/Component/AuthContext';
import { Helmet } from 'react-helmet';
import TextPage from './screen/TextPage';

import Imprint from './screen/Imprint';
import Dataprotection from './screen/Dataprotection';
import TopCoupon from './screen/TopCoupon';
import Blog from './screen/Blog';
import DisplayCategories from './screen/DisplayCategories';



function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <Helmet>
        <title>Getgutschein</title>
        <meta 
        name='description'
        content='Holen Sie sich den Coupon für mehr Rabatt in jedem Geschäft'
 
        />
      </Helmet>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/OpenStore/:storeId/:storeName' element={<OpenStore />} />
          <Route path='/categories' element={<Categories />} />
          <Route path='/TextPage' element={<TextPage />} />
          <Route path='/Impressum' element={<Imprint />} />
          <Route path='/Dataprotection' element={<Dataprotection />} />
          <Route path='/TopCoupon' element={<TopCoupon />} />
          <Route path='/Blog' element={<Blog />} />
          
          <Route path='/DisplayCategories/:categoryid/:categoryName' element={<DisplayCategories />} />
          {/* <Route path='/categories/:categoryNameid/:storeName' element={<ViewCategories />} /> */}

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
