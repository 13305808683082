import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import axios from 'axios';
import { IoArrowRedo } from "react-icons/io5";
import { baseUrl } from '../../BaseUrl';

function Navbar() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(null);

  // Toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Fetch store data from the API
  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/createStore/count`);
      const result = response.data;

      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setStores(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getDataFromDB();
  }, []);

  // Filter stores based on the search term
  useEffect(() => {
    const results = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStores(results);
  }, [searchTerm, stores]);

  // Handle store item click
  // const handleStoreClick = (store) => {
  //   // console.log("this is navbar",store.storeName)
  //   // return
  //   // setSearchTerm("");
  //   navigate(`/OpenStore/${store.id}/${store.storeName}`,{ state: store});
  //   setSelectedStoreId(store.id);
  // };

  const handleStoreClick = (store) => {
    console.log(store); // Debug the store object
    navigate(`/OpenStore/${store.id}/${store.storeName}`, { state: store });
    setSearchTerm("");
    window.location.reload();
  };
  return (
    <div>
      <div style={{ height: '130px' }}>
        <div className='hero-section' style={{
           backgroundImage: `url('/bg.jpg')`, // Replace with the path to your GIF
           backgroundColor: "black",
           backgroundSize:"cover", // Ensures the GIF covers the entire div
           backgroundRepeat: "no-repeat",
           backgroundPosition: 'center',
           height: "140px", // Adjusted for full viewport height
           width: "100%",
           overflow: 'hidden',
           borderBottom: '1px solid #3E3E3F'
        }}>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to={'/'} className="navbar-brand" style={{ fontFamily: "Poppins", fontSize: "2.3rem", textDecoration: "none", color: "black", fontWeight: "bolder" }}>
                <img src='/newlogo.png' alt='Getgutschein' style={{ maxWidth: "100%", height: "60px", borderRadius: 10 }} />
              </Link>
              <div>
                <form className="form-inline w-100 justify-content-center">
                  <div className="input-group w-150">
                    <input
                      className="form-control search-input"
                      style={{ border: "0.7px solid green", borderRadius: "10px", width: "100%" }}
                      type="search"
                      placeholder="Search By Shop"
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <button className="navbar-toggler" type="button" onClick={toggleModal}>
                <span className="navbar-toggler-icon" style={{backgroundColor:"#13d0fb"}}></span>
              </button>
             
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to={'/TopCoupon'} className="nav-link"  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}>
                     
                      Top Gutschein
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/shop'} className="nav-link"  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}>
                    
                      Alle shops
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/categories'} className="nav-link"  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}>
                    
                      Kategorien
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/Blog'} className="nav-link"  style={{
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "White",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}>
                      Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {searchTerm && (
            <div className="search-results" style={{ width: "40%", marginTop: "-3%",marginLeft:"15%" }}>
              {filteredStores.length > 0 ? (
                <ul className="list-unstyled">
                  {filteredStores.map((store) => (
                    <li key={store._id} className="store-item">
                      <div
                        // to={`/OpenStore/${store._id}/${store.storeName}`}
                        className="store-link"
                        style={{textDecoration:"none", color:"#fb7b1c",padding:10,height:"65px",cursor:"pointer" }}
                        onClick={() => handleStoreClick(store)}
                      >
                        <img
                          src={store.StoreImage}
                          alt={store.storeName}
                          style={{
                            width: "75px",
                            height: "60px",
                            objectFit: "contain",
                            borderRadius: "2%",
                          }}
                        />
                        <b style={{marginLeft:10 ,fontSize:17,textDecoration: "none"}}>{store.storeName}</b>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p style={{ color: "#fb7b1c", margin:10,fontWeight:"bolder"}}>Keine Geschäfte gefunden.</p>
              )}
            </div>
          )}

<Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="navbar-nav">
          <li className="nav-item">
                <Link to={'/TopCoupon'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
                  
                <IoArrowRedo  size={25} color="black"/>
                  Top Gutschein
                </Link>
              </li>
            <li className="nav-item">
              <Link to={'/shop'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
              <IoArrowRedo  size={25} color="black"/>
                SHOP
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/categories'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
              <IoArrowRedo  size={25} color="black"/>
                CATEGORIES
              </Link>
            </li>
            <li className="nav-item">
                <Link to={'/Blog'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "#fb7b1c", fontWeight: "normal" }}>
                <IoArrowRedo  size={25} color="black"/>
                  Blogs
                </Link>
              </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
